import { Component, OnInit, ViewChild } from '@angular/core';
import { PopupComponent } from 'src/app/core/components/popup/popup.component';
import { IspvisionService, VisionWs } from '../../../core/services/ispvision.service';
import { UserService } from 'src/app/core/services/user/user.service';
import { UtilService } from 'src/app/core/services/util.service';

@Component({
    selector: 'configurator',
    templateUrl: './configurator.component.html',
    styleUrls: ['./configurator.component.less'],
})
export class ConfiguratorComponent implements OnInit {
    public products: VisionWs.IArticle[];
    public productFeatures: VisionWs.IProductFeature[];
    public productOptionList: VisionWs.IProductOptionReq[];
    public productOptions: VisionWs.IProductOption[];
    public salesPrices: VisionWs.SalesPriceResponse;
    public priceDisplay: string;
    public orderComment: string = '';
    public orderReference: string = '';
    public orderName: string = '';
    public orderFiles: any;
    public reloadForm: boolean = false;
    public maxOptionlistLengthIndex: number;
    public showClearOrderFormBtn: boolean = false;
    public orderStart: boolean = false;

    private currentAction = {
        action: 'orderTableData',
        index: 0,
    };

    public static EMPTY_ROW = [];
    public orderTableData = [];
    private disabledTest = [];
    public globalOrderTableData = [];
    public globalOrderCheck: boolean = false;
    public popupData;
    public popupMirrorData;

    @ViewChild('popupRef') popupRef: PopupComponent;
    @ViewChild('extrasPopup') extrasPopup: PopupComponent;

    constructor(
        public visionWs: IspvisionService,
        public userService: UserService,
        public util: UtilService
    ) {}

    async ngOnInit(): Promise<any> {
        this.productFeatures = [];
        this.productOptionList = [];
        ConfiguratorComponent.EMPTY_ROW = [];
        this.globalOrderTableData = [];
        this.priceDisplay = 'netto';
        this.orderComment = '';
        this.orderReference = '';
        this.orderName = '';
        this.orderFiles = [];
        this.orderTableData = [];
        this.showClearOrderFormBtn = false;
        this.reloadForm = false;

        let prod = (await this.visionWs.getArticle({
            filter_article_is_product: 1,
            filter_article_code: VisionWs.ARTICLE_CODE_MARKIES,
        })) as VisionWs.ArticleResponse;

        this.products = prod.article;

        let tmp = (await this.visionWs.getProductFeature({
            product_code: VisionWs.ARTICLE_CODE_MARKIES.substring(1),
        })) as VisionWs.IProductFeatureResponse;

        if (tmp.product_feature) {
            for (let opts of tmp.product_feature) {
                switch (opts.step_id) {
                    case 1:
                    case 3:
                        ConfiguratorComponent.EMPTY_ROW.push(opts);
                        break;
                    case 2:
                        if (opts.parent_code == null) {
                            this.globalOrderTableData[opts.pf_code] = {
                                pf_name: opts.pf_name,
                                opts: [],
                            };
                        } else {
                            if (this.globalOrderTableData[opts.parent_code]) {
                                this.globalOrderTableData[opts.parent_code].opts.push(opts);
                            }
                        }
                        break;
                }
            }
        }

        this.globalOrderTableData = Object.values(this.globalOrderTableData);

        this.orderTableData.push(this.defaultRow);

        // check if there is a existing shopping cart
        let cartData = await this.visionWs.getShoppingCart();
        if (cartData.orderTableData && cartData.globalOrderTableData) {
            this.globalOrderTableData = cartData.globalOrderTableData;
            this.orderTableData = cartData.orderTableData;
            this.showClearOrderFormBtn = true;
        }

        /**
         * !! OVERRIDES PROD DATA WITH TEST DATA !!
         */
        // const testData = require('./testdata.js');
        // this.orderTableData = testData.ordersTestData;
        // this.globalOrderTableData = testData.globalOptionsTestData;
        await this.updateTableColumns(true);

        this.updateStepThreeOptions();

        await this.refreshProductPrices();
    }

    private async updateTableColumns(skip?: boolean): Promise<void> {
        let action = this.currentAction.action;
        let idx = this.currentAction.index;
        let data = [];
        switch (action) {
            case 'orderTableData':
                data = this[action][idx] || this[action][0];
                for (let dat of data) {
                    await this.getOptionList([dat]);
                }
                if (!skip) {
                    this.currentAction.action = 'globalOrderTableData';
                    await this.updateTableColumns();
                }
                break;
            case 'globalOrderTableData':
                if (this.checkConfiguredProduct(0) > 6) {
                    data = this[action];
                    for (let dat of data) {
                        for (let d of dat['opts']) {
                            await this.getOptionList([d]);
                        }
                    }
                } else {
                    data = this[action][idx]['opts'];
                }
                break;
        }

        await this.refreshProductPrices();
    }

    public get emptyRow(): any[] {
        return ConfiguratorComponent.EMPTY_ROW;
    }

    public checkConfiguredProduct(row: number): number {
        if (!this.orderTableData[row]) return 0;
        return this.orderTableData[row].filter((x) => (x.value && x.value > 0) || x.value.length > 0).length;
    }

    public get nextTableRow(): boolean {
        var row = 0;
        for (const options of this.orderTableData) {
            let filled = this.checkConfiguredProduct(row);
            if (filled <= 6) {
                if (row == 0) {
                    this.globalOrderCheck = false;
                }
                return false;
            } else if (!this.globalOrderCheck && row == 0) {
                this.globalOrderCheck = true;
                this.runGlobalOrderCheck();
            }
            row++;
        }
        return true;
    }

    private async runGlobalOrderCheck(): Promise<void> {
        this.currentAction = {
            action: 'globalOrderTableData',
            index: 0,
        };

        await this.updateTableColumns();
    }

    public get buttonDisabled(): boolean {
        for (let data of this.orderTableData) {
            for (let row of data) {
                if (!row.disabled && !row.value) {
                    return true;
                }
            }
        }

        for (let data of this.globalOrderTableData) {
            for (let opt of data.opts) {
                if (!opt.disabled && !opt.value) {
                    return true;
                }
            }
        }

        return false;
    }

    public updatePriceDisplay(value): void {
        this.priceDisplay = value;
        this.refreshProductPrices();
    }

    public get defaultRow(): any[] {
        let copy = JSON.parse(JSON.stringify(ConfiguratorComponent.EMPTY_ROW));
        copy[copy.findIndex((x) => x.pf_code == '01')].value = 0;
        copy[copy.findIndex((x) => x.pf_code == '02')].value = 0;
        return copy;
    }

    public isCustom(description: string): any {
        return description === '-' || description === '.' || description.match(/\.{3}/);
    }

    public customInputField(data: any): boolean {
        if (data && data.feature_type == 2 && data.options) {
            for (let opts of data.options) {
                if (opts.code == data.value && this.isCustom(opts.description)) {
                    return true;
                }
            }
        }
        data.note = null;
        return false;
    }

    public getSelectedValue(data: any, dataRow?: VisionWs.IProductFeature[]): string {
        if (data.feature_type == 1) {
            return data.value == 0 ? '' : data.value.toString();
        } else {
            if (!data.options) {
                return '';
            }
            let descr = '';

            if (!data.disabled) {
                for (let option of data.options) {
                    if (option.code == data.value) {
                        if (data.note && this.isCustom(option.description)) {
                            return data.note;
                        }
                        return option.description || option.code;
                    }
                }
            }

            for (let row of dataRow) {
                if (!descr && row.parent_code == data.pf_code) {
                    descr = this.getSelectedValue(row, []);
                }
            }
            if (!descr) {
                data.value = '';
            }
            return descr;
        }
    }

    public altSelectOptions(options: VisionWs.IExtraOptions[], pfCode: string): any[] {
        let results = [];
        for (let opt of options) {
            results.push({
                id: opt.code,
                value: opt.description,
            });
        }
        return results;
    }

    private validatePopupForm(): boolean {
        this.visionWs.error = null;

        let result = true;
        let fields = [];

        for (let data of this.popupMirrorData) {
            data.error = false;
            if (data.feature_type == 2 && data.options) {
                if (!data.disabled && !this.optsDisabled(data)) {
                    if (!data.value) {
                        data.error = true;
                        result = false;
                        fields.push(data.pf_desc);
                    }
                    for (let option of data.options) {
                        if (option.code == data.value && this.isCustom(option.description) && !data.note) {
                            data.error = true;
                            result = false;
                            fields.push(data.pf_desc);
                        }
                    }
                }
            }
        }
        if (fields.length > 0) {
            this.visionWs.error = 'De volgende velden zijn niet ingevuld: ' + fields.join(', ') + '.';
        }

        return result;
    }

    public optsDisabled(data) {
        if (!data.options) {
            return false;
        }
        const hasNoOptions = data.options.length === 0;
        const hasOneOption = data.options.length === 1;

        let isFirstOptionNVT = false;
        let isFirstOptionCustom = false;

        if (hasOneOption) {
            const firstOptionDescription = data.options[0].description;
            isFirstOptionNVT = !this.isCustom(firstOptionDescription);
            isFirstOptionCustom = !data.maxHeight && this.isCustom(firstOptionDescription);
        }

        return hasNoOptions || (hasOneOption && (isFirstOptionCustom || isFirstOptionNVT));
    }

    public checkOnInputValue(ev: Event, data: VisionWs.IProductFeature): void {
        const target = ev.target as HTMLInputElement;
        const inputValue = parseInt(target.value, 10) || 0;

        if (data.pf_code === '09' && data.feature_type == 2 && data.options) {
            const descriptions = data.options
                .map((option) => parseInt(option.description, 10))
                .filter((value) => !isNaN(value));
            data.max_value = Math.max(...descriptions);
        }

        if (
            data.feature_type == 2 &&
            data.pf_code === '66' &&
            data.maxHeight &&
            inputValue > data.maxHeight
        ) {
            target.value = data.maxHeight.toString();
        } else {
            if (data.min_value != 0 && inputValue < data.min_value) {
                target.value = data.min_value.toString();
            }

            if (data.max_value != 0 && inputValue > data.max_value) {
                target.value = data.max_value.toString();
            }
        }
    }

    public async updateFormValues(popupRef: PopupComponent): Promise<void> {
        if (!this.validatePopupForm()) {
            return;
        }

        for (const [index, dat] of this.popupMirrorData.entries()) {
            for (let key of ['value', 'note']) {
                if (this.popupData[index][key] != dat[key]) {
                    this.popupData[index][key] = dat[key];
                }
            }
        }

        this.showClearOrderFormBtn = true;

        this.closePopupForm(popupRef);

        await this.visionWs.updateShoppingCart(this.orderTableData, this.globalOrderTableData);
        await this.updateTableColumns(true);

        this.updateStepThreeOptions();

        await this.refreshProductPrices();
    }

    public closePopupForm(popupRef: PopupComponent): void {
        this.popupMirrorData = null;
        popupRef.closePopup();
    }

    public async editableClick(
        ev: Event,
        tableOptionDataIdx: number,
        data: any,
        stepId?: number
    ): Promise<void> {
        // filter data from step id
        if (stepId) {
            let newData = [];
            for (let val of data) {
                if (val.step_id == stepId) {
                    newData.push(val);
                }
            }
            data = newData;
        }

        this.visionWs.resetPopup();

        let optsData = [];
        for (let val of data) {
            if (!val.disabled) {
                optsData.push(val);
            }

            for (let opt of this.orderTableData[tableOptionDataIdx]) {
                if (val.pf_code == opt.parent_code) {
                    optsData.push(opt);
                }
            }
        }

        data = optsData;

        this.currentAction = {
            action: 'orderTableData',
            index: tableOptionDataIdx,
        };
        let totalCols = data.length;

        await this.getOptionList(data);

        this.popupData = data;
        this.popupMirrorData = JSON.parse(JSON.stringify(data));

        if (this.disabledTest.length != totalCols) {
            this.popupRef.erf.nativeElement.classList.remove('hide');
        }
    }

    public hasOptions(data: any): boolean {
        for (const opts of data) {
            if (opts.value && opts.step_id == 3) {
                return true;
            }
        }
        return false;
    }

    private getOptionArray(idx: number): VisionWs.IProductOptionReq[] {
        let optionArray = [];

        for (const option of this.orderTableData[idx] || this.orderTableData[0]) {
            if (option.feature_type == 1 && option.value > 0)
                optionArray.push({
                    feature_code: option.pf_code,
                    num_value: option.value,
                } as VisionWs.IProductOptionReq);

            if (option.feature_type == 2 && option.value > 0)
                optionArray.push({
                    feature_code: option.pf_code,
                    option_code: option.value,
                } as VisionWs.IProductOptionReq);
        }

        for (const column of this.globalOrderTableData) {
            for (const option of column.opts) {
                if (option.value) {
                    optionArray.push({
                        feature_code: option.pf_code,
                        option_code: option.value,
                    });
                }
            }
        }

        return optionArray;
    }

    private async getOptionList(
        data: any,
        product_option_list?: VisionWs.IProductOptionReq[]
    ): Promise<void> {
        this.disabledTest = [];
        if (!product_option_list) {
            product_option_list = this.getOptionArray(this.currentAction.index);
        }
        for (let dat of data) {
            if (dat.feature_type == 2) {
                let productOptions = (await this.visionWs.getProductOption({
                    feature_code: dat.pf_code,
                    product_code: '35000',
                    maximum_number_of_options: 99999,
                    product_option_list: product_option_list,
                })) as VisionWs.IProductOptionResponse;

                dat.options = productOptions.option_list;
                dat.maxHeight = productOptions.maxHeight;
                dat.info = productOptions.info;

                if (dat.value && dat.options) {
                    let found = false;
                    dat.error = true;
                    for (let opt of dat.options) {
                        if (opt.code == dat.value) {
                            found = true;
                            dat.error = false;
                        }
                    }
                    if (!found) {
                        dat.value = '';
                    }
                }

                dat.disabled = this.optsDisabled(dat);

                if (dat.disabled) {
                    dat.error = false;
                    this.disabledTest.push(true);
                }
            }
        }
        this.updateStepThreeOptions();
    }

    private updateStepThreeOptions(): void {
        for (let row of this.orderTableData) {
            for (let opt of row) {
                if (opt.step_id && opt.options && opt.step_id == 3) {
                    for (let p of opt.options) {
                        if (p.description.toLowerCase() === 'nee' && !opt.value) {
                            opt.value = p.code;
                        }
                    }
                }
            }
        }
    }

    public addTableRow(): void {
        this.orderTableData.push(this.defaultRow);
    }

    public rowOptionsDelete(tableDataIdx): void {
        this.orderTableData.splice(tableDataIdx, 1);
        this.visionWs.updateShoppingCart(this.orderTableData, this.globalOrderTableData);
        this.refreshProductPrices();
    }

    public rowOptionsDuplicate(tableDataIdx): void {
        this.orderTableData.push(JSON.parse(JSON.stringify(this.orderTableData[tableDataIdx])));
        this.visionWs.updateShoppingCart(this.orderTableData, this.globalOrderTableData);
        this.refreshProductPrices();
    }

    public getTableClasses(option): string {
        if (option == null) {
            return 'empty';
        }

        if (option.toString().length <= 0 || option <= 0) {
            return 'empty editable';
        }

        return 'editable';
    }

    public async editGlobalOption(
        option: {
            pf_code: string;
            pf_name: string;
            feature_type: number;
            value: string;
            disabled: boolean;
            options: VisionWs.IProductOption[];
        },
        tableOptionDataIdx: number
    ): Promise<void> {
        if (option.disabled) {
            return;
        }

        this.visionWs.resetPopup();

        this.currentAction = {
            action: 'globalOrderTableData',
            index: tableOptionDataIdx,
        };

        let product_option_list = this.getOptionArray(0);

        option.options = (
            (await this.visionWs.getProductOption({
                feature_code: option.pf_code,
                product_code: '35000',
                maximum_number_of_options: 99999,
                product_option_list,
            })) as VisionWs.IProductOptionResponse
        ).option_list;

        if (option.options.length > 0) {
            this.popupRef.erf.nativeElement.classList.remove('hide');
        } else {
            option.disabled = true;
        }
        this.popupData = [option];
        this.popupMirrorData = JSON.parse(JSON.stringify([option]));
    }

    public get popupOptions() {
        if (this.popupMirrorData) {
            let optsDisabled = true;
            for (let data of this.popupMirrorData) {
                if (this.optsDisabled(data)) {
                    data.disabled = true;
                }

                if (!data.disabled) {
                    optsDisabled = false;
                }
            }
            return this.popupMirrorData[0] && !optsDisabled;
        }
    }

    public async preCheckOptions($event: Event): Promise<void> {
        let optionArray = this.getOptionArray(this.currentAction.index);

        for (let data of this.popupMirrorData) {
            let foundItem = false;
            let keyValue = data.feature_type == 1 ? 'num_value' : 'option_code';

            for (let opts of optionArray) {
                if (opts.feature_code == data.pf_code) {
                    foundItem = true;
                    opts[keyValue] = data.value;
                }
            }
            if (!foundItem && data.value) {
                let value = {
                    feature_code: data.pf_code,
                };
                value[keyValue] = data.value;
                optionArray.push(value);
            }
        }

        if (optionArray.length) {
            await this.getOptionList(this.popupMirrorData, optionArray);
        }
    }

    public async sendOrder(): Promise<void> {
        if (this.buttonDisabled || this.orderStart) {
            return;
        }
        this.orderStart = true;
        let orderForm = new FormData();
        orderForm.append('comment', this.orderComment);
        orderForm.append('reference', this.orderReference);
        orderForm.append('name', this.orderName);
        orderForm.append('productCode', VisionWs.PRODUCT_CODE_MARKIES);
        orderForm.append('globalOrderTableData', JSON.stringify(this.globalOrderTableData));
        orderForm.append('orderTableData', JSON.stringify(this.orderTableData));

        if (this.orderFiles && this.orderFiles.length > 0) {
            for (let i = 0; i < this.orderFiles.length; i++) {
                orderForm.append(`orderFiles[${i}]`, this.orderFiles[i], this.orderFiles[i].name);
            }
        }

        let res = await this.visionWs.sendOrder(orderForm);

        this.orderStart = false;

        if (!res.errors) {
            this.reloadForm = true;
            await this.visionWs.clearShoppingCart();
            await this.ngOnInit();
        }
    }

    public async refreshProductPrices(): Promise<void> {
        if (!this.priceDisplay || this.buttonDisabled) {
            return;
        }

        this.salesPrices = await this.visionWs.getItemPrices(this.globalOrderTableData, this.orderTableData);
    }

    public hasRowErrors(code: string, data: any): boolean {
        for (let row of data) {
            if ((row.pf_code == code && row.error) || (row.parent_code == code && row.error)) {
                return true;
            }
        }
        return false;
    }

    public async clearOrderForm(): Promise<void> {
        this.reloadForm = true;
        await this.visionWs.clearShoppingCart();
        this.ngOnInit();
    }
}
